// Dans le "bloc" sit à propos, on ajoute un bouton pour voir plus ou moins si le texte à dépassé une hauteur de 400px
var sitProposHeight = $('.single-sit .sit-a-propos .text');
var btnShowMorePropos = $('.single-sit .btn-view-more');

sitProposHeight.each(function () {
    if ($(this).height() >= 188) {
        $(this).parent().addClass('view-more-active');

        var currentAriaExpanded = false;
        btnShowMorePropos.on('click', function () {
            currentAriaExpanded = !currentAriaExpanded;

            $(this).prev().toggleClass('toggle-height');

            if (currentAriaExpanded) {
                $(this).attr("aria-expanded", true);
            } else {
                $(this).attr("aria-expanded", false);
            }

        });
    }
});

// Gestion de l'affichage au focus de la tooltip avec plusieurs numéros de téléphone
var sitBtnTelephone = $(".single-sit .btn-telephone a");

sitBtnTelephone.each(function () {
    $(this).on("focus", function () {
        $(this).parents(".btn-telephone").toggleClass("active-focus");
    });
});
sitBtnTelephone.each(function () {
    $(this).on("blur", function () {
        $(this).parents(".btn-telephone").removeClass("active-focus");
    });
});

// gestion de l'affichage de la barre itinéraire dans les fiches sit itinéraire
var barreIti = $('.single-sit .barre-iti');
var barreItiSelect = $('select', barreIti);
var barreItiContainer = $('.single-info', barreIti);
var barreItiIcons = $('span[class^=\'icon-ico\']', barreIti);

barreItiSelect.on('change', function () {
    var val = $(this).val();
    barreItiContainer.each(function () {
        $(this).removeClass("is-visible");
    })
    barreItiIcons.each(function () {

        $(this).removeClass("is-visible");
    })
    var elToShow = barreIti.find("#" + val);
    var iconToShow = barreIti.find("span[data-value=" + val + "]");
    elToShow.addClass("is-visible");
    iconToShow.addClass("is-visible");
});

// Gestion de l'attribut aria-expanded, lorsque l'utilisateur affiche ou cache un menu
var btnTable = $("#btn-control-table");

btnTable.on("click", function () {

    if ($(this).attr("aria-expanded") == "false") {
        $(this).attr("aria-expanded", true);
    } else {
        $(this).attr("aria-expanded", false);
    }

});

// cette fonction doit être déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-carte-detail-sit') {
        setTimeout(function () {
            th_maps.initSingleMap(document.querySelector('#carte-detail-sit'));
        }, 250);
    }
});

function callbackMapBlocItineraire(macarte) {
    mapiti = macarte;
    macarte.zoomControl.setPosition('topleft');


    var departlatlng = {
        lat: $(macarte._container).data('departlat') * 1,
        lng: $(macarte._container).data('departlng') * 1
    };
    if (departlatlng.lat && departlatlng.lng) {
        var depart_text = $(macarte._container).data('departtext');

        var etapeIconDepart = L.divIcon({
            className: 'custom-etape-icon-actif',
            html: '<div class="marker-pin-actif"><span class="icon-ico-oeil"></span></div>',
            shadowUrl: null,
            iconSize: [40, 40],
            iconAnchor: [20, 20],
        });
        var markerdepart = th_maps.createMarker(macarte, departlatlng, etapeIconDepart);

    }

    // maps-popup
    if ($('.th-slider .points').length > 0) {
        var markers = [];

        $('.points').each(function (index) {

            var etapeIconActif = L.divIcon({
                className: 'custom-etape-icon-actif',
                html: '<div class="marker-pin-actif" data-marker="' + (index + 1) + '"><span class="icon-ico-oeil"></span></div>',
                shadowUrl: null,
                iconSize: [40, 40],
                iconAnchor: [20, 20],
            });

            var etapeIcon = L.divIcon({
                className: 'custom-etape-icon',
                html: '<div class="marker-pin" data-marker="' + (index + 1) + '"><span class="icon-ico-oeil"></span></div>',
                iconSize: [40, 40],
                iconAnchor: [20, 20],
            });

            if (this.getAttribute('data-lat') && this.getAttribute('data-lng')) {
                var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};

                if (latlng.lat && latlng.lng) {

                    var marker = th_maps.createMarker(macarte, latlng, etapeIcon);


                    marker.on('popupopen', function (popup) {
                        lazyLoadInstance = new LazyLoad({
                            elements_selector: ".lazy"
                        });
                    });

                    marker.on('click', function () {

                        $([document.documentElement, document.body]).animate({
                            scrollTop: ($(".sit-points-interets").offset().top - $(".sit-points-interets").height()) - 30
                        }, 500);

                        if (typeof sliderpoi != 'undefined') {
                            sliderpoi_th_slider.goto(index);
                        }

                        this.setIcon(etapeIconActif);
                    });

                    markers.push(marker);
                }
            }
        });
    }


    // marker chart elevation
    var count = 1;
    var markerselevation = [];
    $('.marker-elevation').each(function () {
        var elevationIcon = L.divIcon({
            className: 'custom-elevation-icon',
            html: '<div class="marker-pin" data-markerindex="' + count + '"></div>',
            iconSize: [12, 12],
            iconAnchor: [6, 6],
        });

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        var marker = th_maps.createMarker(macarte, latlng, elevationIcon);
        markerselevation.push(marker);

        count++;
    });

    if (markerselevation.length > 0) {
        var group = new L.featureGroup(markerselevation);
    }

    var mapMarkers = $(".leaflet-marker-icon");
    mapMarkers.each(function () {
        $(this).attr("tabindex", "-1");
    });

}

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// Page Itinéraire, graphique JS

if ($('#denivele').length > 0) {

    // Chart.js, lourd (170ko) mais beaucoup d'option dont des interractions
    // https://www.chartjs.org/

    //Récupération de la couleur de la page, et rajout de 80 à la fin du #hexa pour mettre la transparent de 50% pour le fill
    var chartColors = "#141412";
    var bgChartCurve = "#ECECEB";

    //Initialisation du graph avec les valeurs en DATA sur l'élément canvas généré en PHP en fonction des informations fournis dans le KML
    var YAxis = $('#denivele').attr('data-YAxis').split(',');
    //# 13039 axe des ordonnées minimum à 100
    YAxis.push(100);
    var XAxis = $('#denivele').attr('data-XAxis').split(',');


    var ctx = $('#denivele');
    var myChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: XAxis,
            datasets: [
                {
                    label: 'DÉNIVELÉ (m)',
                    data: YAxis,
                    borderColor: chartColors,
                    fill: true,
                    backgroundColor: bgChartCurve,
                    pointRadius: 0,
                    pointHoverRadius: 5,
                    pointHitRadius: 10,
                    lineTension: 0.1,
                }
            ]
        },

        hover: {
            mode: 'index'
        },
        options: {
            responsive: true,
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'DISTANCE (km)',
                        fontStyle: "bold",
                        fontFamily: 'brandon-grotesque'
                    },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        stepSize: 20
                    },
                    gridLines: {
                        drawOnChartArea: false
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'ALTITUDE (m)',
                        fontFamily: 'brandon-grotesque',
                        fontStyle: "bold",
                        padding: {
                            bottom: 20
                        }
                    }
                }],
            },
            tooltips: {
                bodyFontFamily: 'brandon-grotesque',
                titleFontFamily: 'brandon-grotesque',
                displayColors: false,
            }
        },
    });

    document.getElementById("denivele").onmousemove = function (evt) {
        var activePoint = myChart.getElementAtEvent(event);

        // make sure click was on an actual point
        if (activePoint.length > 0) {
            $('[data-markerindex=' + activePoint[0]._index + ']').addClass('active');
            //$('#overlay-itineraire-etape').addClass('open');
            // $('.shadow-overlay').addClass('open');
        } else {
            $('[data-markerindex]').removeClass('active');
        }
    };
}


// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// Initialisation du slider pour la section sit "points d'intérêts"


var sliders_pois = document.querySelectorAll('.sit-points-interets .th-slider:not(.no-autoload)');
for (i = 0; i != sliders_pois.length; i++) {
    var sliderpoi = sliders_pois[i];
    var sliderpoi_th_slider = initSlider(sliderpoi);
}


function initSlider(slider) {
    var parent = slider.parentNode;
    while (!parent.className.match(/th-slider-wrapper/)) {
        parent = parent.parentNode;
    }

    var navButtons = parent.querySelectorAll('.nav-buttons button');

    var s = new thSlider(slider, {
        draggable: true,
        scrollListener: true,
        scrollModeMaxWidth: 1024,
        oninit: function (slider) {
            if (navButtons.length === 2) {
                thSliderTools.onInitNav(slider, navButtons);
            }
        },
        onchange: function (slider) {
            if (navButtons.length > 0) {
                thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
            }
        }
    });

    return s;
}


var barreSingleSIT = document.getElementById('barre-single-sit');

if (barreSingleSIT) {
    var body = document.querySelector(".single");
    var barreSingleSITPosition = barreSingleSIT.offsetTop;

    if (isTabletPortraitOrSmalller()) {
        window.onscroll = function () {
            myScrollFunction()
        };

        function myScrollFunction() {
            var res = barreSingleSITPosition - document.documentElement.scrollTop;

            if (res <= 0) {
                body.classList.add("is-sticky");
            } else {
                body.classList.remove("is-sticky");
            }
        }
    }

}


// Smooth scroll vers la section horaires - au click sur le bouton voir les horaires dans les couvertures FMA

$(document).ready(function () {

    $("#anchor-horaires-link").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 200, function () {
                window.location.hash = hash;
            });
        }
    });

    if ($('.single-sit').length > 0) {
        if (window.opener && window.opener.location.host === window.location.host) {

            $('.js-btn-back-list').removeClass('hide').on('click', function () {
                window.location.href = window.opener.location.href;
            });
        }
    }

});







